/* profiles.css */

.profile-header {
  text-align: center;
  margin-bottom: -20px;
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-details,
.profile-itineraries {
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 2px;
}

.profile-details h3,
.profile-itineraries h3 {
  margin-bottom: 5px;
}

.profile-details p {
  margin: 5px 0;
}

.itinerary-list {
  list-style: none;
  padding: 0;
}

.itinerary-item {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.login-prompt {
  text-align: center;
  font-size: 18px;
  color: #6c757d;
}
